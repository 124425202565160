<template>
  <div class="container">
    <PublicHeader :totalName="$t('notice').title" />
    <!-- <div class="backMain">
      <div @click="back">
        <img
          class="backBox"
          src="@/assets/bitop/back_night.svg"
          alt=""
          width="21"
        />
      </div>
      <div>
        <img class="zxgg" src="@/assets/newApp/zxgg.png" alt="" />
      </div>
    </div> -->

    <div class="zxgg"></div>

    <div class="containerBox" @scroll="handleScroll">
      <div v-for="(item, index) in noticeList" :key="index">
        <div class="nBox" @click="
          $router.push({
            name: 'MsgDetail',
            query: {
              contentId: item.Id
            }
          })
          ">
          <div class="c-c-sb">
            <div>
              <div class="nMainContent">
                <!-- <div class="nContent">{{ item.TypeName }}</div> -->
                <div class="nTxtContent">{{ item.Ttile }}</div>
              </div>
              <div class="nTxt">
                {{ item.ShowCreateTime | getLocalTime }}
              </div>
            </div>
            <div class="xqTxt">
              {{ $t('notice').ckxq }}<van-icon name="arrow" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { getLocalTime,getLang } from '@/utils/validate'
import PublicHeader from '@/components/publicHeaderNew'
export default {
  components: {
    PublicHeader
  },
  filters: {
    getLocalTime
  },
  data() {
    return {
      routerName: '',
      noticeList: '',
      noList: false,
      page: 1,
      size: 20
    }
  },
  computed: {
    //   ...mapGetters(['basicConfig'])
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.noticeList = []
    this.getNoticeList() //获取公告列表
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // 处理滚动事件的逻辑
      if (!this.noList) {
        this.noList = true
        this.page = this.page + 1
        this.getNoticeList()
      }
    },
    async getNoticeList() {
      // 轮播公告
      const res = await userApi.getContentFY('Notice', {
        lang: getLang(),
        page: this.page,
        size: this.size
      })
      var list = res.Data
      if (this.page == 1) {
        this.noticeList = list
      } else {
        this.noticeList = this.noticeList.concat(list)
      }
      var cur = this.page * this.size
      if (cur > res.TotalCount || cur == res.TotalCount) {
        this.noList = true
      } else {
        this.noList = false
      }
    },
    back() {
      if (this.routerName !== '') {
        this.$router.push({ name: this.routerName, query: this.query })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.c-c-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  min-height: 100vh;
  width: 100%;
  background: #F7F9FE;
  color: #515151;
}

.backMain {
  position: fixed;
}

.zxgg {
  height: 44px;
}

.backBox {
  height: 44px;
  display: flex;
  position: absolute;
  align-items: center;
  margin-left: 16px;
  // color: #777E90;
}

.containerBox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.nBox {
  width: 345px;
  height: 79px;
  padding: 0px 15px;
  background: #ffffff;
  border-radius: 10px;
  margin: 15px 15px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.nMainContent {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.nContent {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  width: 76px;
  height: 24px;
  font-size: 12px;
  font-weight: 700;
  background: #2868D5;
  color: #ffffff;
  padding: 3px 0px;
}

.xqTxt {
  color: #2868D5;
}

.nTxtContent {
  color: #515151;
  font-size: 15px;
  font-weight: 600;
  width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.nTxt {
  font-size: 12px;
  color: #84819c;
}
</style>
